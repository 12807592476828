<template>
  <b-row>
    <b-col>
      <div>
        <div v-if="soulLetter" class="text-center">
          <img class="feathericons-phone p-4 bg-primary" />
          <h6 class="font-weight-bold mt-3 mb-3 ">
            {{ $t("kingOfTheCastle.detailsSoulLetter.headline") }}
            {{ soulLetter.sender.profile.givenName }}
            {{ soulLetter.sender.profile.familyName }}
          </h6>
        </div>
        <perfect-scrollbar>
          <table
            v-if="soulLetter"
            id="style-3"
            class="table style-3  table-hover"
          >
            <tbody>
              <!-- SUBJECT -->
              <tr>
                <td>
                  <span class="font-weight-bold">{{
                    $t("kingOfTheCastle.detailsSoulLetter.title")
                  }}</span>
                </td>
                <td>
                  {{ soulLetter.title }}
                </td>
              </tr>
              <!-- BOOKED AT -->
              <tr>
                <td>
                  <span class="font-weight-bold">{{
                    $t("kingOfTheCastle.detailsSoulLetter.status")
                  }}</span>
                </td>
                <td>
                  {{ soulLetter.status }}
                </td>
              </tr>
              <tr>
                <td>
                  <span class="font-weight-bold">{{
                    $t("kingOfTheCastle.detailsSoulLetter.latestRevisionDate")
                  }}</span>
                </td>
                <td>
                  {{ moment(soulLetter.latestRevision.createTimeStamp).format("YYYY-MM-DD") }}
                </td>
              </tr>
            </tbody>
          </table>
        </perfect-scrollbar>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";
import * as moment from "moment";

export default {
  name: "SoulLetterSummary",
  props: {
    soulLetter: Object
  },
  data() {
    return {
      moment: moment,
      currentUser: null,
    };
  },
  computed: {
    ...mapGetters("users",["getCurrentUser"])
  },
  created() {
    if (this.getCurrentUser) {
      this.currentUser = this.getCurrentUser;
    }
  }
};
</script>

<style scoped></style>
